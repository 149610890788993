import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import { IsNullOrEmpty, MetinKisalt, SeoUrlRegex } from "../../libs/utils";
import Language from "../../locales/GetLanguageResourceResponse.json";
import Image from "next/image";
import DeliveryImg from "../../public/static/imgs/template/delivery.svg";
import SupportImg from "../../public/static/imgs/template/support.svg";
import VoucherImg from "../../public/static/imgs/template/voucher.svg";
import ReturnImg from "../../public/static/imgs/template/return.svg";
import SecureImg from "../../public/static/imgs/template/secure.svg";
import KapakImg from "../../public/milli-mucadelenin-yuzuncu-yili/kapak.jpg";
import "swiper/css";

const Slider = ({ data }) => {
  const [language, setLanguage] = useState("tr");
  const languageStore = useSelector((state) => state.language);

  return (
    <>
      <section className="section-box">
        <div className="banner-hero banner-homepage3">
          <div className="container-banner">
            <div className="box-swiper">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                slidesPerView={1}
                pagination={{ clickable: true }}>
                {/* <SwiperSlide>
                  <Image
                    src={KapakImg}
                    title="Milli Mücadelenin 100. Yılı"
                    className="ptt-slider-img"
                    width={650}
                    height={400}
                  />
                  <div className="box-slide-bg-1">
                    <h1 className="font-58 mt-20">
                      Milli Mücadelenin 100. Yılı
                    </h1>
                    <div className="mt-10">
                      <ul className="list-disc">
                        <li className="font-lg">
                          {" "}
                          {Language[languageStore].guvenliOdemeTitle}
                        </li>

                        <li className="font-lg">
                          {" "}
                          {Language[languageStore].destekHattiTitle}
                        </li>
                      </ul>
                    </div>
                    <div className="mt-30 mb-120">
                      <Link
                        className="btn btn-pttFilateli btn-md"
                        href="milli-mucadelenin-yuzuncu-yili"
                      >
                        {Language[languageStore].kataloguIncele}
                      </Link>
                    </div>
                  </div>
                </SwiperSlide> */}

                {data &&
                  data?.map((slider, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img
                          src={slider.resimLink}
                          title={slider.urunAd}
                          className="ptt-slider-img"
                          width={650}
                          height={400}
                        />
                        <div className="box-slide-bg-1">
                          <h1 className="font-58 mt-20">{slider.urunAd}</h1>
                          <div className="mt-10">
                            <ul className="list-disc">
                              <li className="font-lg">
                                {" "}
                                {Language[languageStore].guvenliOdemeTitle}
                              </li>

                              <li className="font-lg">
                                {" "}
                                {Language[languageStore].destekHattiTitle}
                              </li>
                            </ul>
                          </div>
                          <div className="mt-30 mb-120">
                            <Link
                              className="btn btn-pttFilateli btn-md"
                              href={`/urunler/${SeoUrlRegex(slider.urunAd)}/${
                                slider.urunId
                              }`}>
                              {Language[languageStore].anasayfaSliderBtnTitle}
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <div className="section-box mt-10">
        <div className="container">
          <ul className="list-col-5">
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={DeliveryImg} />
                </div>
                <div className="info-right">
                  <Link href="/kurumsal/emisyon-programi">
                    <h5 className="font-md-bold color-gray-900">
                      {Language[languageStore].emisyonProgramiTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {" "}
                      {Language[languageStore].emisyonProgramiSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={SupportImg} />
                </div>
                <div className="info-right">
                  <Link href="/kurumsal/bize-ulasin">
                    <h5 className="font-md-bold color-gray-900">
                      {" "}
                      {Language[languageStore].destekHattiTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {Language[languageStore].destekHattiSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={VoucherImg} />
                </div>
                <div className="info-right">
                  <Link
                    href="https://www.ptt.gov.tr/duyurular?page=1&announcementType=2"
                    target="_blank">
                    <h5 className="font-md-bold color-gray-900">
                      {" "}
                      {Language[languageStore].duyurularTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {" "}
                      {Language[languageStore].duyurularSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={ReturnImg} />
                </div>
                <div className="info-right">
                  <Link href="/kurumsal/iptal-ve-iade-kosullari">
                    <h5 className="font-md-bold color-gray-900">
                      {" "}
                      {Language[languageStore].urunIadesiTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {Language[languageStore].urunIadesiSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={SecureImg} alt="Güvenli Ödeme" />
                </div>
                <div className="info-right">
                  <h5 className="font-md-bold color-gray-100">
                    {" "}
                    {Language[languageStore].guvenliOdemeTitle}
                  </h5>
                  <p className="font-sm color-gray-500">
                    {" "}
                    {Language[languageStore].guvenliOdemeSlogan}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Slider;
